@font-face {
  font-family: 'AmpleAlt';
  src: url('assets/fonts/AmpleAlt-400.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'AmpleAlt';
  src: url('assets/fonts/AmpleAlt-600.ttf') format('truetype');
  font-weight: 600;
}
/**
 * Prevent adjustments of font size after orientation changes in IE on Windows Phone and in iOS.
 */
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  color: #212121;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.6;
  font-weight: 400;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
/**
 * Add the correct display in IE 9-.
 */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent; /* 1 */
  -webkit-text-decoration-skip: objects; /* 2 */
}
a,
a:hover {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  line-height: 1.1;
  font-weight: 700;
  margin: 0;
}
b,
strong {
  font-weight: 600;
}
/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}
p {
  margin-top: 1em;
  margin-bottom: 1em;
}
p:first-of-type {
  margin-top: 0;
}
p:last-of-type {
  margin-bottom: 0;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
/**
 * Add the correct display in IE 9-.
 * 1. Add the correct display in IE.
 */
figcaption,
figure,
main {
  display: block;
}
figure {
  margin: 0;
}
img {
  max-width: 100%;
  height: auto;
  border-style: none;
}
iframe {
  max-width: 100%;
}
/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  height: 0; /* 1 */
  overflow: visible; /* 2 */
  box-sizing: content-box; /* 1 */
}
/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block;
}
/**
  * Add the correct display in iOS 4-7.
  */
audio:not([controls]) {
  display: none;
  height: 0;
}
/* Forms
   ========================================================================== */
/**
 * Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  margin: 0;
}
/**
  * Show the overflow in IE.
  * 1. Show the overflow in Edge.
  */
button,
input {
  /* 1 */
  overflow: visible;
}
/**
  * Remove the inheritance of text transform in Edge, Firefox, and IE.
  * 1. Remove the inheritance of text transform in Firefox.
  */
button,
select {
  /* 1 */
  text-transform: none;
}
/**
  * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
  *    controls in Android 4.
  * 2. Correct the inability to style clickable types in iOS and Safari.
  */
button,
html [type="button"], /* 1 */
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; /* 2 */
}
button,
[type='button'],
[type='submit'] {
  border: none;
  cursor: pointer;
}
/**
  * Remove the inner border and padding in Firefox.
  */
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
/**
  * Restore the focus styles unset by the previous rule.
  */
button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}
/**
  * 1. Correct the text wrapping in Edge and IE.
  * 2. Correct the color inheritance from `fieldset` elements in IE.
  * 3. Remove the padding so developers are not caught out when they zero out
  *    `fieldset` elements in all browsers.
  */
legend {
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  color: inherit; /* 2 */
  padding: 0; /* 3 */
  box-sizing: border-box; /* 1 */
  white-space: normal; /* 1 */
}
/**
  * 1. Add the correct display in IE 9-.
  * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
  */
progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}
/**
  * Remove the default vertical scrollbar in IE.
  */
textarea {
  overflow: auto;
}
/**
  * 1. Add the correct box sizing in IE 10-.
  * 2. Remove the padding in IE 10-.
  */
[type='checkbox'],
[type='radio'] {
  padding: 0; /* 2 */
  box-sizing: border-box; /* 1 */
}
/**
  * Correct the cursor style of increment and decrement buttons in Chrome.
  */
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
/**
  * 1. Correct the odd appearance in Chrome and Safari.
  * 2. Correct the outline style in Safari.
  */
[type='search'] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}
/**
  * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
  */
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
/**
  * 1. Correct the inability to style clickable types in iOS and Safari.
  * 2. Change font properties to `inherit` in Safari.
  */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}
/* Interactive
   ========================================================================== */
/*
 * Add the correct display in IE 9-.
 * 1. Add the correct display in Edge, IE, and Firefox.
 */
details, /* 1 */
 menu {
  display: block;
}
/*
  * Add the correct display in all browsers.
  */
summary {
  display: list-item;
}
/* Scripting
    ========================================================================== */

/**
  * Add the correct display in IE 9-.
  */
canvas {
  display: inline-block;
}
/**
  * Add the correct display in IE.
  */
template {
  display: none;
}
/* Hidden
    ========================================================================== */
/**
  * Add the correct display in IE 10-.
  */
[hidden] {
  display: none;
}

@media screen and (min-width: 1024px) {
  #root {
    min-height: 100vh;
    display: grid;
    grid-template-areas:
      'header header'
      'sidebar content'
      'footer footer';
    grid-template-columns: 268px 1fr;
    grid-template-rows: auto 1fr auto;
  }
}

/* sweetalert */
.swal2-confirm,
.swal2-deny,
.swal2-cancel {
  display: block;
  font-family: 'AmpleAlt', Arial, Helvetica, sans-serif;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  text-transform: lowercase;
  line-height: 1.4;
  padding: 8px 30px;
  border: none;
  background: linear-gradient(270deg, #fba642 0%, #fe7e36 100%) !important;
  border-radius: 4px;
  outline: none;
  min-width: 120px;
  text-align: center;
  outline: 0;
  margin: 0 5px;
}
.swal2-cancel {
  background: transparent !important;
  border: 1px solid #fe7e36 !important;
  color: #fe7e36 !important;
}

.swal2-confirm:hover:not([disabled]),
.swal2-deny:hover:not([disabled]),
.swal2-cancel:hover:not([disabled]) {
  background: linear-gradient(270deg, #ff8e4f 0%, #fe5a36 100%) !important;
  color: #fff !important;
  margin: 0 5px;
}
.swal2-icon.swal2-info {
  border-color: #f8bb86 !important;
  color: #f8bb86 !important;
}
.swal2-file:focus,
.swal2-input:focus,
.swal2-textarea:focus {
  border: 1px solid #f8bb86 !important;
  outline: 0;
  box-shadow: 0 0 3px #f8bb86 !important;
}
